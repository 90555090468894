import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import ProjectList from '../components/Projects/ProjectList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categories, categoryFilter, site, yoast = [], wordpressCategory, wordpressWpSettings, siteSettings } = data
    const { options = null } = siteSettings
    const { wordpressUrl } = wordpressWpSettings
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug, pathPrefix } = pageContext
    const title = `${category} Category`
    const featuredImage = wordpressCategory && wordpressCategory.acf.featureImage ? wordpressCategory.acf.featureImage : ''

    return (
      <>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast}
          location={location}
        />
        <PageHeader headerTitle={title} headerBackgroundImage={featuredImage} location={location} />
        <ProjectList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix={`/project-category/${slug}/`}
        />
      </>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectCategoryPage($category_id: [Int], $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    },
    postsOnPage: allWordpressWpProjects(
      filter: {
        project_category: {in: $category_id}
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    wordpressCategory: wordpressWpProjectCategory( wordpress_id: {in: $category_id} ) {
      name
      slug
      path
      count
      acf {
        title
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ... GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      wordpress_id
      wordpress_parent
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          acf {
            title
          }
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
